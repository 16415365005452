import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MQTTComponent from '../components/MQTTComponentESS'; // Ajuste o caminho conforme necessário
import Header from '../components/Header';
import { buscarDescricaoEntradas, saveDescription, buscarDadosSensor } from '../http/Esp';
import GraphComponent from '../components/graphComponent'; // Ajuste o caminho conforme necessário

// Definindo os tipos para as chaves do estado de entrada
type InputStateKeys = 
  | 'entrada_digital_1' 
  | 'entrada_digital_2'
  | 'entrada_digital_3'
  | 'analogica_1'
  | 'analogica_2'
  | 'analogica_3'
  | 'analogica_4'
  | 'analogica_5'
  | 'analogica_6'
  | 'temperatura 1'
  | 'temperatura 2'
  | 'temperatura 3';

  // const descriptions: Record<InputStateKeys, string> = {
  //   entrada_digital_1: "Estado atual da máquina: Ligada ou Desligada",
  //   entrada_digital_2: "Estado atual da máquina: Ligada ou Desligada",
  //   entrada_digital_3: "Estado atual da máquina: Ligada ou Desligada",
  //   analogica_1: "Valor da entrada analógica 1",
  //   analogica_2: "Valor da entrada analógica 2",
  //   analogica_3: "Valor da entrada analógica 3",
  //   analogica_4: "Valor da entrada analógica 4",
  //   analogica_5: "Valor da entrada analógica 5",
  //   analogica_6: "Valor da entrada analógica 6",
  //   "temperatura 1": "Temperatura em °C",
  //   "temperatura 2": "Temperatura em °C",
  //   "temperatura 3": "Temperatura em °C",
  // };


  

const Broker: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Obtém o ID da URL
  const [descriptions, setDescriptions] = useState<Record<string, string>>({});
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [newDescription, setNewDescription] = useState("");
  const [inputStates, setInputStates] = useState<Record<InputStateKeys, string>>({
    entrada_digital_1: "00",
    entrada_digital_2: "00",
    entrada_digital_3: "00",
    analogica_1: "00",
    analogica_2: "00",
    analogica_3: "00",
    analogica_4: "00",
    analogica_5: "00",
    analogica_6: "00",
    "temperatura 1": "00",
    "temperatura 2": "00",
    "temperatura 3": "00",
  });

  //variável para armazenar o id do sensor
  const [sensorId, setSensorId] = useState<number>(0);

  // Função para adicionar alertas
  const addAlert = (message: string) => {
    const updates = parseMessage(message);
    if (updates) {
      setInputStates((prevStates) => ({
        ...prevStates,
        ...updates,
      }));
    }
  };
  
  const parseMessage = (message: string): Partial<Record<InputStateKeys, string>> | null => {
    console.log("Mensagem recebida:", message); // Log da mensagem recebida
    const updates: Partial<Record<InputStateKeys, string>> = {};

    // Expressões regulares separadas para cada tipo de entrada
    const digitalInputRegex = /(entrada_digital_\d+)\s+(desligado|ligado)/g;
    const analogInputRegex = /(analogica_\d+):\s*([\d.]+)/g;
    // const temperatureRegex = /(temperatura_\d+):\s+([\d]+(?:\.\d{1,2})?)°C/g;
    const temperatureRegex = /temperatura\s*(\d+):\s*(\d+)/g;


    // Captura entradas digitais
    let match;
    while ((match = digitalInputRegex.exec(message)) !== null) {
        //@ts-ignore 
        const [_, key, value] = match; // Extrai chave e valor
        console.log(`Correspondência digital encontrada: ${key} = ${value}`); // Log da correspondência
        updates[key as InputStateKeys] = value; // Atribui o valor correspondente
    }

    // Captura entradas analógicas
    while ((match = analogInputRegex.exec(message)) !== null) {
        //@ts-ignore 
        const [_, key, value] = match; // Extrai chave e valor
        console.log(`Correspondência analógica encontrada: ${key} = ${value}`); // Log da correspondência
        updates[key as InputStateKeys] = value; // Atribui o valor correspondente
    }

    // Captura temperaturas
    while ((match = temperatureRegex.exec(message)) !== null) {
        //@ts-ignore 
        const [_, key, value] = match; // Extrai chave e valor
        console.log(`Correspondência de temperatura encontrada: ${key} = ${value}`); // Log da correspondência
        updates[`temperatura ${key}` as InputStateKeys] = value;
      }

    return Object.keys(updates).length > 0 ? updates : null;
};


  useEffect(() => {
    async function fetchDescriptions() {
      if (!id) return;
      try {
        const response = await buscarDescricaoEntradas(id);
        console.log("Descrições recebidas:", response);
        if (response?.descricao) {
          const descricaoMap: Record<string, string> = {};
          response.descricao.forEach((desc: { input_key: string; description: string }) => {
            descricaoMap[desc.input_key] = desc.description;
          });
          setDescriptions(descricaoMap);
        }
      } catch (error) {
        console.error("Erro ao buscar descrições:", error);
      }
    }
    fetchDescriptions();
  }, [id]);


  useEffect(() => {
    async function fetchSensorData() {
      if (!id) return;
      try {
        const response = await buscarDadosSensor(id);
        console.log("Dados recebidos:", response);
        
        if (response?.sensor) {
          setSensorId(response.sensor.id); // Corrigido para pegar o ID do sensor corretamente
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    }
    fetchSensorData();
  }, [id]);
  
  

  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-100">
      <Header /> {/* Inclui o Header fixo */}
      <main className="flex-1 p-6">
        <div className="max-w-7xl mx-auto bg-neutral-800 rounded-lg shadow-lg">
          <div className="p-6">
            <h1 className="text-3xl font-bold mb-4 text-center">Dashboard de Alertas - {id}</h1>
            

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {/* Componente MQTT para receber mensagens */}
              <MQTTComponent selectedMac={`${id}`} onMessageReceived={addAlert} />

              {/* Exibe os estados das entradas */}
              {Object.entries(inputStates).map(([key, value]) => (
                <div key={key} className="bg-gray-700 rounded-lg p-4 shadow-md flex flex-col justify-between">

                  {/* o trecho abaixo realiza a captura do input de descrição em cada card do painel, e realiza a requisição para update enviando os parametros de input_key
                  mac e descrição do card para a req no arquivo Esp.ts, o update ocorre com enter ou clicando fora do input. Se existe registro para o MAC ele da um update, se nao, dá insert */}
              
              {editingKey === key ? (
                <input
                  type="text"
                  className="text-lg font-semibold bg-gray-600 p-1 rounded text-white"
                  value={newDescription}
                  onChange={(e) => setNewDescription(e.target.value)}
                  onBlur={() => saveDescription(id ?? "", newDescription, key as InputStateKeys)} 
                  onKeyDown={(e) => e.key === "Enter" && saveDescription(id ?? "", newDescription, key as InputStateKeys)}
                  autoFocus
                />
              ) : (
                <h3
                  className="font-semibold text-lg mb-2 cursor-pointer"
                  onClick={() => {
                    setEditingKey(key);
                    setNewDescription(descriptions[key] || "");
                  }}
                >
                  {descriptions[key] || "Sem descrição disponível"}
                </h3>
              )}


                  <p className="text-sm mb-2">{key.replace(/_/g, ' ')}</p>

                  <div className={`text-3xl font-bold ${value === 'desligado' ? 'text-red-500' : 'text-green-500'}`}>
                    {value}
                  </div>
                </div>
              ))}
            </div>

            {/* Componente de gráfico */}
            <GraphComponent sensorId={sensorId} />

   
          </div>
        </div>
      </main>
    </div>
  );
};

export default Broker;
