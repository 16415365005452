import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import CadastrarUsuario from './pages/usuarios/CadastrarUsuario';
import EditarUsuario from './pages/usuarios/EditarUsuario';
import Broker from './pages/Broker';
import ProtectedRoute from './auth/ProtectedRoute';
import CadastrarMaquina from './pages/maquinas/CadastrarMaquina';
import Maquinas from './pages/maquinas/Maquinas';
import Esp32 from './pages/esp/Esp32';
import CadastrarEsp from './pages/esp/CadastrarEsp';
import EditarEsp from './pages/esp/EditarEsp';
import Topicos from './pages/Topicos';
import Clientes from './pages/clientes/Clientes';
import CadastrarCliente from './pages/clientes/CadastrarCliente';
import EditarCliente from './pages/clientes/EditarCliente';
import Usuarios from './pages/usuarios/Usuarios';
import Index from './pages/Index';
import EditarMaquinas from './pages/maquinas/EditarMaquinas';
import EditarParametros from './pages/esp/EditarParametros';
import Dash from './pages/dash';


const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dash" element={<Dash />} />
        <Route path="/usuarios" element={<ProtectedRoute element={<Usuarios />} />} />
        <Route path='/cadastro_usuario' element={<ProtectedRoute element={<CadastrarUsuario />} />} />
        <Route path='/editar_usuario/:id' element={<ProtectedRoute element={<EditarUsuario />} />} />
        <Route path="/broker/:id" element={<ProtectedRoute element={<Broker />} />} />
        <Route path="/index" element={<ProtectedRoute element={<Index />} />} />
        <Route path="/maquinas" element={<ProtectedRoute element={<Maquinas />} />} />
        <Route path="/editar_maquina/:id" element={<ProtectedRoute element={<EditarMaquinas />} />} />
        <Route path="/cadastro_maquina" element={<ProtectedRoute element={<CadastrarMaquina />} />} />
        <Route path="/esp32" element={<ProtectedRoute element={<Esp32 />} />} />
        <Route path="/cadastro_esp32" element={<ProtectedRoute element={<CadastrarEsp />} />} />
        <Route path="/editar_esp32/:id" element={<ProtectedRoute element={<EditarEsp />} />} />
        <Route path="/editar_parametros/:id" element={<ProtectedRoute element={<EditarParametros />} />} />
        <Route path="/topicos" element={<ProtectedRoute element={<Topicos />} />} />
        <Route path="/clientes" element={<ProtectedRoute element={<Clientes />} />} />
        <Route path="/cadastro_cliente" element={<ProtectedRoute element={<CadastrarCliente />} />} />
        <Route path="/editar_cliente/:id" element={<ProtectedRoute element={<EditarCliente />} />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
