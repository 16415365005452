import axios from "axios";
import exp from "constants";

interface espRequest {
    mac: string;
    client_id: string;
    notificacaoStatus: string;
    telegramGroupId: string;
}

interface desativarEspRequest {
    id: number;
}

interface ativarEspRequest {
    id: number;
}

interface espRequestId{
    id: number;
}



const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function buscarEsp(page: number) {
    try {

        const response = await axios.get(`${API_BASE_URL}/sensor?page=${page}&limit=5`);
        
        if(response.status === 200){
            return response.data;
        } else {

            throw new Error('Erro ao buscar esps');
        }
    } catch (error) {
        console.error('Erro ao buscar esps:', error);
    }
}

export async function buscarEspAll(page: number) {
    try {

        const response = await axios.get(`${API_BASE_URL}/sensor-all?page=${page}&limit=5`);
        
        if(response.status === 200){
            return response.data;
        } else {

            throw new Error('Erro ao buscar esps');
        }
    } catch (error) {
        console.error('Erro ao buscar esps:', error);
    }
}


export async function buscarEspCliente(id: string | null, page: number) {
    try {
        const response = await axios.get(`${API_BASE_URL}/sensor-client/${id}?page=${page}&limit=5`);
        if(response.status === 200){
            return response.data;
        } else {
            throw new Error('Erro ao buscar esps do cliente');
        }
    } catch (error) {
        console.error('Erro ao buscar esps do cliente:', error);
    }
}



// buscar Esp por id
export async function buscarEspPorId({ id }: espRequestId) {
    try {
        const response = await axios.get(`${API_BASE_URL}/sensor-id/${id}`);
        if(response.status === 200){
            return response.data;
        } else {
            throw new Error('Erro ao buscar esp');
        }
    } catch (error) {
        console.error('Erro ao buscar esp:', error);
        console.log('id aqui:', id);
    }
}

// buscar Esps sem paginação
export async function buscarEspSemPaginacao() {
    try {
        const response = await axios.get(`${API_BASE_URL}/sensor`);
        if(response.status === 200){
            return response.data;
        } else {
            throw new Error('Erro ao buscar esps');
        }
    } catch (error) {
        console.error('Erro ao buscar esps:', error);
    }
}




export async function desativarEsp({ id }: desativarEspRequest) {
    try {
        const response = await axios.post(`${API_BASE_URL}/sensor-delete/${id}`);
        if(response.status === 200){
            return response;
        } else {
            throw new Error('Erro ao desativar esp');
        }
    } catch (error) {
        console.error('Erro ao desativar esp:', error);
    }
}

export async function ativarEsp({ id }: ativarEspRequest) {
    try {
        const response = await axios.post(`${API_BASE_URL}/sensor-activate/${id}`);
        if(response.status === 200){
            return response;
        } else {
            throw new Error('Erro ao ativar esp');
        }
    } catch (error) {
        console.error('Erro ao ativar esp:', error);
    }
}

export async function cadastrarEsp({ mac, client_id, notificacaoStatus, telegramGroupId}: espRequest) {
    try {
        const response = await axios.post(`${API_BASE_URL}/sensor-register`, {
            mac,
            client_id,
            notificacaoStatus,
            telegramGroupId
        });
        if(response.status === 200){
            return response;
        }else{
            throw new Error('Erro ao cadastrar esp');
        }
    } catch (error) {
        console.error('Erro ao cadastrar esp:', error);
        throw error; 

    }
}

export async function editarEsp(id: number, { mac, client_id, notificacaoStatus, telegramGroupId}: espRequest) {
    try {
        const response = await axios.post(`${API_BASE_URL}/sensor-update/${id}`, {
            mac,
            client_id,
            notificacaoStatus,
            telegramGroupId,
        });
        if(response.status === 200){
            return response;
        }else{
            throw new Error('Erro ao editar esp');
        }
    } catch (error) {
        console.error('Erro ao editar esp:', error);
    }
}

export async function buscarParametrosPorId({ id }: espRequestId) {
    try {
        const response = await axios.get(`${API_BASE_URL}/sensor-parametros/${id}`);
        if(response.status === 200){
            return response.data;
        } else {
            throw new Error('Erro ao buscar parametros');
        }
    } catch (error) {
        console.error('Erro ao buscar parametros:', error);
    }
}

export async function editarParametros(id: number, parametros: any) {
    try {
        const response = await axios.post(`${API_BASE_URL}/sensor-parametros-update/${id}`, parametros);
        console.log('parametros na req', parametros);
        if (response.status === 200) {
            return response; // Retornar dados da resposta
        } else {
            throw new Error('Erro ao editar parâmetros');
        }
    } catch (error) {
        console.error('Erro ao editar parâmetros:', error);
        throw error; // Propagar o erro para tratamento posterior
    }
}

export async function deletarParametros(id: number) {
    try {
        const response = await axios.post(`${API_BASE_URL}/sensor-parametros-delete/${id}`);
        if (response.status === 200) {
            return response; // Retornar dados da resposta
        } else {
            throw new Error('Erro ao deletar parâmetros');
        }
    } catch (error) {
        console.error('Erro ao deletar parâmetros:', error);
        throw error; // Propagar o erro para tratamento posterior
    }
}

//busca descrição de entradas
export async function buscarDescricaoEntradas(mac: string) {
    try {
        const response = await axios.get(`${API_BASE_URL}/sensor-device-descriptions/${mac}`);
        if(response.status === 200){
            return response.data;
        } else {
            throw new Error('Erro ao buscar descrição de entradas');
        }
    } catch (error) {
        console.error('Erro ao buscar descrição de entradas:', error);
    }
}

//cadastrar descrição de entradas
export async function saveDescription(mac: string, description: string, input_key: string) {
    try {
        const response = await axios.post(`${API_BASE_URL}/save-descriptions/${mac}`, {
            description,
            input_key
        });

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Erro ao salvar descrição");
        }
    } catch (error) {
        console.error("Erro ao salvar descrição:", error);
    }
}

//busca logs
export async function buscarLogs(id: number | string) {
    try {
        const response = await axios.get(`${API_BASE_URL}/logs/${id}`);

        if (response.status === 200) {
            console.log('response.data', response.data);
            return response.data;
        } else {
            throw new Error("Erro inesperado ao buscar logs");
        }
    } catch (error: any) {
        console.error("Erro ao buscar logs:", error.response?.data || error.message);
        return null; // Retorna `null` para evitar erros no frontend
    }
}

//busca dados do sensor por MAC
export async function buscarDadosSensor(mac: string) {
    try {
        const response = await axios.get(`${API_BASE_URL}/sensor-data/${mac}`);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Erro inesperado ao buscar dados do sensor");
        }
    } catch (error: any) {
        console.error("Erro ao buscar dados do sensor:", error.response?.data || error.message);
        return null; // Retorna `null` para evitar erros no frontend
    }
}
