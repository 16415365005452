import React, { useEffect, useState } from 'react';

interface Props {
  selectedMac: string; // O MAC address selecionado
  onMessageReceived: (message: string) => void; // Função que recebe a mensagem
}

const RealTimeMessages: React.FC<Props> = ({ selectedMac, onMessageReceived }) => {
  //@ts-ignore 
  const [messages, setMessages] = useState<string[]>([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  //@ts-ignore 
  const [lastMessage, setLastMessage] = useState<string | null>(null); // Altere aqui

  useEffect(() => {
    if (selectedMac) {
      const eventSource = new EventSource(`${API_BASE_URL}/events?mac=${selectedMac}`);
      
      eventSource.onmessage = (event) => {
        const newMessage = event.data;
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setLastMessage(newMessage); // Atualiza a última mensagem
        
        // Chama o manipulador de mensagem aqui
        onMessageReceived(newMessage); // Passa a nova mensagem para o manipulador
      };

      eventSource.onerror = (error) => {
        console.error('Erro na conexão SSE:', error);
        eventSource.close();
      };

      return () => {
        eventSource.close(); // Fecha a conexão quando o componente é desmontado ou o MAC é alterado
      };
    }
  }, [selectedMac, onMessageReceived]); // Refaz a conexão SSE quando o MAC ou o manipulador muda

  return (
    null
    // <div className="p-4 rounded-lg">
    //   <p className="mt-2">
    //     Última mensagem recebida: <span className="font-semibold">{lastMessage || 'Nenhuma mensagem'}</span>
    //   </p>
    // </div>
  );
};

export default RealTimeMessages;