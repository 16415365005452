import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { buscarLogs, buscarParametrosPorId } from "../http/Esp";

interface GraphComponentProps {
  sensorId: number;
}

const GraphComponent: React.FC<GraphComponentProps> = ({ sensorId }) => {
  const [groupedLogs, setGroupedLogs] = useState<Record<string, any[]>>({});
  const [filteredData, setFilteredData] = useState<Record<string, any[]>>({});
  const [filterType, setFilterType] = useState<"all" | "hourly" | "sample">("hourly");
  const [parametros, setParametros] = useState<Record<string, { min_value: number; max_value: number }>>({});

  useEffect(() => {
    async function fetchData() {
      if (!sensorId) return;
      try {
        const data = await buscarLogs(sensorId);
        if (data && typeof data === "object") {
          setGroupedLogs(data);
        } else {
          setGroupedLogs({});
        }
      } catch (error) {
        console.error("Erro ao buscar logs:", error);
        setGroupedLogs({});
      }
    }
    fetchData();
  }, [sensorId]);

  useEffect(() => {
    async function fetchParametros() {
      try {
        const response = await buscarParametrosPorId({ id: sensorId });
        if (response && Array.isArray(response.parametros)) {
          const mappedParametros: Record<string, { min_value: number; max_value: number }> = {};
  
          response.parametros.forEach((param: { parameter_name: string; min_value: string; max_value: string }) => {
            const nomeFormatado = param.parameter_name.replace(/_/g, " "); // Corrige nome para bater com o gráfico
            mappedParametros[nomeFormatado] = {
              min_value: parseFloat(param.min_value),
              max_value: parseFloat(param.max_value),
            };
          });
  
          setParametros(mappedParametros);
        }
      } catch (error) {
        console.error("Erro ao buscar parâmetros:", error);
      }
    }
    fetchParametros();
  }, [sensorId]);
  

  useEffect(() => {
    const processData = () => {
      const newData: Record<string, any[]> = {};

      Object.entries(groupedLogs).forEach(([message, data]) => {
        if (filterType === "hourly") {
          newData[message] = groupByHour(data);
        } else if (filterType === "sample") {
          newData[message] = sampleData(data);
        } else {
          newData[message] = data;
        }
      });

      setFilteredData(newData);
    };

    if (Object.keys(groupedLogs).length > 0) {
      processData();
    }
  }, [groupedLogs, filterType]);

  const groupByHour = (data: any[]) => {
    const grouped: Record<string, { sum: number; count: number }> = {};
    
    data.forEach(({ time, value }) => {
      const date = new Date(time);
      date.setHours(date.getHours() - 3);
      const hour = date.toISOString().slice(0, 13);

      if (!grouped[hour]) grouped[hour] = { sum: 0, count: 0 };
      grouped[hour].sum += value;
      grouped[hour].count += 1;
    });

    return Object.keys(grouped).map((hour) => ({
      time: hour + ":00",
      value: grouped[hour].sum / grouped[hour].count,
    }));
  };

  const tickFormatter = (time: string) => {
    const date = new Date(time);
    date.setHours(date.getHours() - 3);
    return date.toISOString().slice(11, 16);
  };

  const sampleData = (data: any[]) => data.filter((_, index) => index % 5 === 0);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Monitoramento do Sensor {sensorId}</h1>

      <div className="mb-4">
        <label className="mr-2 font-semibold">Exibir:</label>
        <select
          value={filterType}
          onChange={(e) => setFilterType(e.target.value as "all" | "hourly" | "sample")}
          className="border rounded px-3 py-1 bg-black text-gray-100"
        >
          <option value="hourly">Média por hora</option>
          <option value="sample">Amostragem (1 a cada 5 pontos)</option>
          <option value="all">Todos os dados</option>
        </select>
      </div>

      {Object.keys(filteredData).length === 0 ? (
        <p className="text-gray-500">Nenhum dado encontrado.</p>
      ) : (
        Object.entries(filteredData).map(([message, data]) => {
          const parametro = parametros[message];

          return (
            <div key={message} className="bg-black text-gray-100 shadow-lg p-4 rounded-lg mb-6">
              <h2 className="text-xl font-semibold mb-2">{message}</h2>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="time" tickFormatter={tickFormatter} />
                  <YAxis />
                  <Tooltip contentStyle={{ backgroundColor: "#222", color: "#fff", borderRadius: "8px" }} />
                  <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2} />

                  {/* Aplica marcações para qualquer temperatura encontrada na API */}
                  {parametro && message.startsWith("temperatura") && (
                    <>
                      <ReferenceLine y={parametro.min_value} label="Mínimo" stroke="blue" strokeDasharray="3 3" />
                      <ReferenceLine y={parametro.max_value} label="Máximo" stroke="red" strokeDasharray="3 3" />
                    </>
                  )}
                </LineChart>
              </ResponsiveContainer>
            </div>
          );
        })
      )}
    </div>
  );
};

export default GraphComponent;