import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from "recharts";
import { buscarLogs } from "../http/Esp";

const Dashboard: React.FC = () => {
  const [groupedLogs, setGroupedLogs] = useState<Record<string, any[]>>({});
  const [filteredData, setFilteredData] = useState<Record<string, any[]>>({});
  const [filterType, setFilterType] = useState<"all" | "hourly" | "sample">("all");

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await buscarLogs(27); // ID do sensor
        if (data && typeof data === "object") {
          setGroupedLogs(data);
        } else {
          setGroupedLogs({});
        }
      } catch (error) {
        console.error("Erro ao buscar logs:", error);
        setGroupedLogs({});
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    // Processa os dados conforme o filtro selecionado
    const processData = () => {
      let newData: Record<string, any[]> = {};

      Object.entries(groupedLogs).forEach(([message, data]) => {
        if (filterType === "hourly") {
          newData[message] = groupByHour(data);
        } else if (filterType === "sample") {
          newData[message] = sampleData(data);
        } else {
          newData[message] = data;
        }
      });

      setFilteredData(newData);
    };

    processData();
  }, [groupedLogs, filterType]);

  // Agrupa os valores por hora, calculando a média
  const groupByHour = (data: any[]) => {
    const grouped: Record<string, { sum: number; count: number }> = {};
    data.forEach(({ time, value }) => {
      const hour = time.slice(0, 13); // Pega apenas "YYYY-MM-DD HH"
      if (!grouped[hour]) grouped[hour] = { sum: 0, count: 0 };
      grouped[hour].sum += value;
      grouped[hour].count += 1;
    });

    return Object.keys(grouped).map((hour) => ({
      time: hour + ":00", // Formata para "YYYY-MM-DD HH:00"
      value: grouped[hour].sum / grouped[hour].count,
    }));
  };

  // Seleciona 1 a cada 5 pontos para reduzir densidade
  const sampleData = (data: any[]) => data.filter((_, index) => index % 5 === 0);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Monitoramento de Sensores</h1>

      {/* Dropdown para selecionar tipo de exibição */}
      <div className="mb-4">
        <label className="mr-2 font-semibold">Exibir:</label>
        <select
          value={filterType}
          onChange={(e) => setFilterType(e.target.value as "all" | "hourly" | "sample")}
          className="border rounded px-3 py-1"
        >
          <option value="all">Todos os dados</option>
          <option value="hourly">Média por hora</option>
          <option value="sample">Amostragem (1 a cada 5 pontos)</option>
        </select>
      </div>

      {Object.keys(filteredData).length === 0 ? (
        <p className="text-gray-500">Nenhum dado encontrado.</p>
      ) : (
        Object.entries(filteredData).map(([message, data]) => (
          <div key={message} className="bg-white shadow-lg p-4 rounded-lg mb-6">
            <h2 className="text-xl font-semibold mb-2">{message}</h2>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" tickFormatter={(time) => time.slice(11, 16)} />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2} />
                <ReferenceLine y={25} label="Limite" stroke="red" strokeDasharray="3 3" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ))
      )}
    </div>
  );
};

export default Dashboard;
