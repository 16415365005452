import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { loginAuth } from '../http/Login';
 
const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await loginAuth({ username: email, password });
      
      if (response && response.token) {
        const token = response.token;
        localStorage.setItem('token', token);
        
        login(token);
        navigate('/index');
      } else {
        setError('Erro ao fazer login. Tente novamente.');
      }
    } catch (error) {
      setError('Erro ao fazer login. Tente novamente.');
      console.log('teste');
    }
  };

  return (
<div className="h-screen flex flex-col items-center justify-center bg-black px-4">
  {/* Logo */}
  <img src="/logo.png" alt="Logo" className="max-w-sm h-auto mb-8" />

  {/* Card de Login */}
  <div className="w-full max-w-2xl px-5 py-10 bg-white shadow-lg rounded-xl text-center">

    <form onSubmit={handleSubmit} className="space-y-5">
      {/* Campo Usuário */}
      <div>
        <input
          type="text"
          placeholder="Usuário ou E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-custom-blue transition"
        />
      </div>

      {/* Campo Senha */}
      <div>
        <input
          type="password"
          placeholder="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-custom-blue transition"
        />
      </div>

      {/* Erro */}
      {error && <p className="text-red-500 text-sm">{error}</p>}

      {/* Botão de Login */}
      <button
        type="submit"
        className="w-full px-4 py-3 bg-custom-blue text-white rounded-lg font-medium transition hover:bg-blue-700 focus:ring-4 focus:ring-blue-300"
      >
        Entrar
      </button>
    </form>
  </div>
</div>

  );
};

export default Login;
