import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import Header from '../components/Header';
import { buscarEsp, buscarEspCliente } from '../http/Esp';
import { useAuth } from '../auth/AuthContext'; // Importar o AuthContext
// import MQTTComponent from '../components/MQTTComponentSubs'; // Importar o componente MQTT
import MQTTComponentESS from '../components/MQTTComponentESS'; 


interface Sensor {
  id: number;
  mac: string;
  status: string;
  client_name: string;
  machine_name: string;
  deleted_at: string;
}

const Broker: React.FC = () => {
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [alerts, setAlerts] = useState<string[]>([]); // Estado para armazenar alertas
  const {  role, client_id } = useAuth();


  const navigate = useNavigate(); // Inicializar o useNavigate

  // Função para buscar os ESPs com paginação
  const fetchEsp = async (page: number) => {
    try {
        if(role === 'admin') {
        const response = await buscarEsp(page);
        setSensors(response.sensores); // Atualiza os sensores recebidos
        setTotalPages(Math.ceil(response.total / 5)); // Define o total de páginas
      } else {
        const response = await buscarEspCliente(client_id, page);
        setSensors(response.sensores); // Atualiza os sensores recebidos
        setTotalPages(Math.ceil(response.total / 5)); // Define o total de páginas
      }
    } catch (error) {
      console.error('Erro ao buscar ESPs:', error);
    }
  };

  // Chama o fetchEsp quando a página é alterada
  useEffect(() => {
    fetchEsp(currentPage);
  }, [currentPage]);


  

  // Funções para mudar de página
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // Função para navegar para a página do Broker com o MAC do sensor
  const handleCardClick = (mac: string) => {
    navigate(`/broker/${mac}`); // Navega para o Broker passando o MAC como parâmetro
  };

    // Função para adicionar alertas
    const addAlert = (message: string) => {
        setAlerts((prevAlerts) => [...prevAlerts, message]); // Adiciona a nova mensagem de alerta
      };

  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-100">
      <Header />
      <main className="flex-1 p-6">
        <div className="max-w-full mx-auto bg-neutral-900 rounded-lg shadow-lg">
          <div className="p-6">
            <h1 className="text-2xl font-bold mb-4"></h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {sensors.length > 0 ? (
                sensors.map((sensor) => (
                  <div
                    key={sensor.id}
                    className="bg-gray-800 rounded-lg p-4 shadow-md space-y-2 cursor-pointer hover:bg-gray-700 transition-colors"
                    onClick={() => handleCardClick(sensor.mac)} // Torna o card clicável
                  >
                    <h2 className="text-lg font-semibold text-white">
                      {sensor.machine_name}
                    </h2>
                    {/* quantidade de msg */}
                    <p className="text-gray-400">MAC: {sensor.mac}</p>
                    <p className="text-gray-400">Cliente: {sensor.client_name}</p>
                    <p className="text-gray-400">Status: {sensor.deleted_at ? 'Desativado' : 'Ativado'}</p>

                      {/* conexão com o topico e ultima mensagem recebida */}
                        <MQTTComponentESS selectedMac={`${sensor.mac}`} onMessageReceived={addAlert} />

                  </div>
                ))
              ) : (
                <p className="text-gray-400">Nenhum sensor encontrado.</p>
              )}
            </div>

            {/* Controles de Paginação */}
            <div className="mt-6 flex justify-between">
              <button
                onClick={prevPage}
                disabled={currentPage === 1}
                className={`px-4 py-2 rounded-lg ${
                  currentPage === 1 ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'
                }`}
              >
                Página Anterior
              </button>
              <span className="px-4 py-2">
                Página {currentPage} de {totalPages}
              </span>
              <button
                onClick={nextPage}
                disabled={currentPage === totalPages}
                className={`px-4 py-2 rounded-lg ${
                  currentPage === totalPages ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'
                }`}
              >
                Próxima Página
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Broker;